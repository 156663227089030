import React from "react";
import { BsPersonFill } from "react-icons/bs";

const ClientCard = (data) => {
  const datos = data.data;

  return (
    <>
      {datos.client.length > 0 ? (
        datos.client.map((client) => (
          <div className="w-full bg-pink-100 border border-purple-600 rounded-md px-4">
            <div className="relative flex flex-col mt-4">
              <div className="px-4 py-5 flex-auto">
                <div className="text-gray-900 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                  <BsPersonFill />
                </div>
                <span className="mx-4">Cliente</span>
                <h6 className="text-2xl mb-1 font-extrabold">{client.name}</h6>
                <p className="mb-4 text-gray-500">{client.phone}</p>
                <div className="mt-6">
                  {client.fee.map((fee) => (
                    <div>
                      <p>
                        Cuota:{" "}
                        {fee.haber > fee.debe
                          ? `A favor ${fee.haber - fee.debe}`
                          : `Debe ${fee.debe - fee.haber}`}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="w-full bg-pink-100 border border-purple-600 rounded-md px-4">
          <div className="relative flex flex-col mt-4">
            <div className="px-4 py-5 flex-auto">
              <div className="text-gray-900 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                <BsPersonFill />
              </div>
              <span className="mx-4">Cliente</span>
              <h6 className="text-2xl mb-1 font-extrabold">
                No hay cliente en la propiedad
              </h6>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ClientCard;
