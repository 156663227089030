import React from "react";
import { useParams } from "react-router-dom";
import OwnedCards from "../components/OwnedCards";
import Layout from "./Layout";
import { Redirect } from "react-router";

const Owned = () => {
  let { id } = useParams();

  return (
    <Layout>
        {
          sessionStorage.getItem("Token-user") ? (
            <OwnedCards id={id} />
          ) : 
          (
            <Redirect to="/" />
          )
        }
    </Layout>
  );
};

export default Owned;
