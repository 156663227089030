import React from 'react'
import Login from './pages/Login'
import Home from './pages/Home'

import {Switch, Route, BrowserRouter as Router} from 'react-router-dom'
import Owned from './pages/Owned'

const Routes = () => {
    return (
        <>
            <Router>
                <Switch>
                    <Route exact path="/" component={Login} /> 
                    <Route path="/home" component={Home} />
                    <Route path="/owned/:id" component={Owned} />
                </Switch>  
            </Router>  
        </>
    )
}

export default Routes