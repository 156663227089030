import React, { useEffect, useState } from "react";
import axios from "axios";
import ClientCard from "./ClientCard";
import PaymentCard from "./PaymentCard";
import { Redirect } from "react-router";

const OwnedCards = (id) => {
  const [data, setData] = useState();

  const API_URI = sessionStorage.getItem("API_URI");

  const getAll = async () => {
    await axios
      .get(`${API_URI}/ownerships/${id.id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": sessionStorage.getItem("Token-user"),
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          sessionStorage.removeItem("Token-user");
          sessionStorage.removeItem("API_URI");
          <Redirect to="/" />;
        }
      });
  };

  console.log(id.id);

  useEffect(() => {
    getAll();
  }, [id.id]);

  return (
    <>
      {data ? (
        <section className="relative">
          <div className="container mx-auto">
            <div className="flex flex-wrap items-center">
              <div className="w-full px-4">
                <h1 className="text-5xl text-center">{data.name}</h1>
                <div className="flex pt-12 flex-wrap">
                  <ClientCard data={data} />
                  <div className="w-full mt-12">
                    <h1 className="text-3xl">Pagos</h1>
                    <div className="w-full rounded-md px-4">
                      <PaymentCard data={data} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        "Loading..."
      )}
    </>
  );
};

export default OwnedCards;
