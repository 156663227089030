import React from "react";
import { FaMoneyBillAlt } from "react-icons/fa";

const PaymentCard = (data) => {
  const datos = data.data;

  return (
    <>
      {datos.payments.length > 0 ? (
        datos.payments.map((payment) => (
          <>
            <div className="relative flex inline-flex bg-gray-300 w-full md:w-6/12 rounded-xl border border-purple-600 flex-row mt-4">
              <div className="px-4 py-5 flex-auto">
                <div className="text-gray-900 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                  <FaMoneyBillAlt />
                </div>
                <span className="mx-4">{payment.name}</span>
                <h6 className="text-2xl mb-1 font-extrabold">{payment.name}</h6>
                <p className="mb-4 text-gray-500">
                  Deuda: {payment.debe - payment.haber}
                </p>
              </div>
            </div>
          </>
        ))
      ) : (
        <div className="relative flex flex-col bg-gray-300 w-full rounded-xl border border-purple-600 mt-4">
          <div className="px-4 py-5 flex-auto">
            <div className="px-4 py-5 flex-auto">
              <h6 className="text-2xl mb-1 font-extrabold">No hay pagos</h6>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentCard;
