import React, { useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import Loading from "../components/Loading";
import { useGlobal } from "../context/GlobalContext";

const Login = () => {
  const { setToken } = useGlobal();
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    const { username, password } = e.target;

    setLoading(true);

    console.log(username.value, password.value);

    const baseURL = "https://consorcio-euge.herokuapp.com/api";

    sessionStorage.setItem("API_URI", baseURL);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: username.value, password: password.value }),
    };
    fetch(`${baseURL}/auth/signin`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);

        if (!data.message) {
          setToken(data.token);
          sessionStorage.setItem("Token-user", data.token);
          setLoading(false);
          history.push("/home");
        } else {
          console.log(data.message);
          setLoading(false);
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  const sessionToken = sessionStorage.getItem("Token-user");

  return !sessionToken ? (
    loading ? (
      <div className="flex bg-yellow-50 text-white items-center h-screen">
        <div className="flex-1 content-center">
          <Loading />
        </div>
      </div>
    ) : (
      <div class="flex flex-col items-center justify-center w-screen h-screen bg-gray-200 text-gray-700">
        <form
          class="flex flex-col bg-white rounded shadow-lg p-12 mt-12"
          action=""
          onSubmit={handleSubmit}
        >
          <label class="font-semibold text-xs" for="usernameField">
            Username or Email
          </label>
          <input
            class="flex items-center h-12 px-4 w-64 bg-gray-200 mt-2 rounded focus:outline-none focus:ring-2"
            id="username"
            type="text"
            name="username"
            placeholder="Username"
          />
          <label class="font-semibold text-xs mt-3" for="passwordField">
            Password
          </label>
          <input
            class="flex items-center h-12 px-4 w-64 bg-gray-200 mt-2 rounded focus:outline-none focus:ring-2"
            id="password"
            type="password"
            name="password"
            placeholder="******************"
          />
          <button class="flex items-center justify-center h-12 px-6 w-64 bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700">
            Login
          </button>
        </form>
      </div>
    )
  ) : (
    <Redirect to={"/home"} />
  );
};

export default Login;
