import React from "react";

const MobileMenuBar = () => {

  const sidebar = document.getElementById("sidebar");

  const handleClick = () => {
    sidebar.classList.toggle("-translate-x-full");
  };

  return (
    <div className="bg-pink-300 text-gray-900 flex justify-between md:hidden">
      {/* Logo */}
      <a href="" className="block px-4 text-gray-900 font-bold">
        Euge
      </a>

      {/* Mobile menu button */}
      <button
        className="mobile-menu-button p-4 focus:outline-none focus:bg-pink-700"
        onClick={handleClick}
      >
        <svg
          className="h-5 x-5"
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>
  );
};

export default MobileMenuBar;
