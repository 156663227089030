import React from "react";
import "../styles/Loading.css";

const Loading = () => {
  return (
    <>
      <svg className="logo">
          <circle
            className="relative animation-spin"
            cx="140"
            cy="140"
            r="140"
          ></circle>
        </svg>
    </>
  );
};

export default Loading;
