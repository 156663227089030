import React from "react";
import { useGlobal } from "../context/GlobalContext";
import { Disclosure } from "@headlessui/react";
import { FaCity, FaRegBuilding } from "react-icons/fa";
import { BiBuildingHouse } from "react-icons/bi";
import { BsHouseFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const { data } = useGlobal();

  const datos = data.datos;

  return (
    <div
      className="bg-pink-300 z-50 text-gray-800 w-64 space-y-6 px-2 py-7 absolute inset-y-0 left-0 transform -translate-x-full md:relative md:translate-x-0 transition duration-200 ease-in-out"
      id="sidebar"
    >
      {/* Logo */}
      <NavLink to="/home" className="text-gray-900 flex items-center space-x-2 px-4">
        <span className="text-2xl font-extrabold">Euge</span>
      </NavLink>
      {/* Nav */}
      <nav>
        {datos
          ? datos.map((ciudad) => (
              <Disclosure key={ciudad._id}>
                <Disclosure.Button className="flex block py-2.5 px-4 rounded transition items-center duration-200 hover:bg-pink-700 hover:text-white w-full">
                  <FaCity className="mx-1" />
                  <span>{ciudad.name}</span>
                </Disclosure.Button>
                <Disclosure.Panel>
                  {ciudad.propTypes.map((propType) => (
                    <Disclosure key={propType._id}>
                      <Disclosure.Button className="flex block py-2.5 px-4 rounded transition items-center duration-200 hover:bg-pink-500 hover:text-white w-full">
                        <BiBuildingHouse className="mx-2" />
                        {propType.name}
                      </Disclosure.Button>
                      <Disclosure.Panel>
                        {propType.ownerships.map((owned) => (
                          <NavLink
                            key={owned._id}
                            to={`/owned/${owned._id}`}
                            className="flex block py-2.5 px-4 rounded transition items-center duration-200 hover:bg-blue-400 hover:text-white w-full"
                          >
                            {propType.name === "Departamentos" ? <FaRegBuilding className="mx-4" /> : <BsHouseFill className="mx-4" />}
                            {owned.name}
                          </NavLink>
                        ))}
                      </Disclosure.Panel>
                    </Disclosure>
                  ))}
                </Disclosure.Panel>
              </Disclosure>
            ))
          : "Loading..."}
      </nav>
    </div>
  );
};

export default Navbar;
