import { createContext, useContext, useState } from "react";

export const GlobalContext = createContext();

export const useGlobal = () => useContext(GlobalContext);

export const GlobalProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [token, setToken] = useState();

  const createTask = (datos) => {
    setData({ datos });
  };

  const updateTask = (id, updatedTask) => {
    setData([
      ...data.map((task) =>
        task.id === id ? { ...task, ...updatedTask } : task
      ),
    ]);
  };

  const deleteTask = (id) =>
    setData({ ...data.filter((task) => task.id !== id) });

  return (
    <GlobalContext.Provider
      value={{ data, token, setToken, createTask, updateTask, deleteTask }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
