import React, { useEffect } from "react";
import { Redirect } from "react-router";
import Navbar from "../components/Navbar";
import MobileMenuBar from "../components/MobileMenuBar";
import axios from "axios";
import { useGlobal } from "../context/GlobalContext";

const Layout = ({ children }) => {
  const SessionToken = sessionStorage.getItem("Token-user");
  const API_URI = sessionStorage.getItem("API_URI");

  const { createTask } = useGlobal();

  const getAll = async () => {
    await axios
      .get(API_URI + "/zones", {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": sessionStorage.getItem("Token-user"),
        },
      })
      .then((response) => {
        createTask(response.data);
      });
  };

  useEffect(() => {
    getAll();
  }, []);

  return SessionToken ? (
    <div className="relative min-h-screen md:flex">

      {/* sidebar */}
      <Navbar />

      <div className="block flex-1">
        {/* Mobile menu bar */}
        <div className="flex-1 bg-pink-300 md:h-10">
          <MobileMenuBar />
        </div>

        {/* Content */}
        <div className="flex-1 p-10 text-2xl font-bold bg-yellow-50">
          {children}
        </div>
      </div>
    </div>
  ) : (
    <Redirect to={"/"} />
  );
};

export default Layout;
